/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchResults, fetchResultsComplete, refineAddFacetsValues } from 'generic/core/search/actions';
import { fetchWidgets, setDashboardLoading } from 'generic/core/dashboard/actions';
import { snackActions } from 'generic/utils/snackbar';
import Highcharts from 'highcharts';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import {
  getColorForFrequency,
  getRandomColor,
  getToneColor,
  monoColors,
} from 'generic/utils/colorUtils';
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

import { format } from 'generic/utils/dateUtils';
import Bar from 'generic/components/dashboard-items/Bar';
import ColumnHisto from 'generic/components/dashboard-items/ColumnHisto';
import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import Datatable from 'generic/components/dashboard-items/Datatable';
import DocumentList from 'generic/components/dashboard-items/DocumentList';
import Map from 'generic/components/dashboard-items/Map';
import NetworkGraph from 'generic/components/dashboard-items/NetworkGraph';
import Pie from 'generic/components/dashboard-items/Pie';
import Sankey from 'generic/components/dashboard-items/Sankey';
import SolidGauge from 'generic/components/dashboard-items/SolidGauge';
import Sunburst from 'generic/components/dashboard-items/Sunburst';
import Treemap from 'generic/components/dashboard-items/Treemap';
import WidgetContainer from 'generic/containers/WidgetContainer';
import Wordcloud from 'generic/components/dashboard-items/Wordcloud';
import { fastGedOpen } from 'generic/core/ged/actions';
import { getFieldIdFromFormFields, getValueOrFirstValueFromArray } from 'generic/utils/qesUtils';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';

const ResponsiveGridLayout = WidthProvider(Responsive);

const {
  DATE_INTERVAL_COMPARATOR,
  DYNAMIC_DASHBOARD,
} = QES_CONSTANTS;

const computeTitle = (title, serie) => {
  let finalTitle = title || '';
  if (!_.isEmpty(serie)) {
    const { totalUnique } = getValueOrFirstValueFromArray(serie);
    if (totalUnique) {
      finalTitle = `${finalTitle} (${totalUnique})`;
    }
  }

  return finalTitle;
};

const generateDatatableFirstCell = (dispatch, fields, fieldName, computeLink) => (value) => (
  <Box style={{ display: 'flex', cursor: 'pointer', alignItems: 'flex-start' }}>
    <IconButton
      size="small"
      onClick={
        () => { window.open(computeLink(value), '_blank'); }
      }
      sx={{
        padding: 0,
        marginRight: 1,
        '& .MuiSvgIcon-root': {
          color: 'primary.attenuated',
        },
        '&:hover .MuiSvgIcon-root': {
          color: 'primary.main',
        },
      }}
    >
      <OpenInNewOutlined fontSize="small" />
    </IconButton>
    <Typography
      sx={{
        width: '240px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: '14px',
      }}
      onClick={(event) => {
        dispatch(refineAddFacetsValues([
          {
            champ: getFieldIdFromFormFields(fields, fieldName),
            strategie: value,
          },
        ], (event.ctrlKey || event.metaKey)));
      }}
      title={value}
    >
      {value}
    </Typography>
  </Box>
);

const DashboardChartsContainer = () => {
  const currentTheme = useColorSchemeDetector();

  const { entitiesColors, relationsEntitiesColors } = currentTheme.HIGHCHARTS;

  const strategy = useSelector((state) => state.search.results.strategie);
  const {
    base: activeBaseId,
    champs: fields,
  } = useSelector((state) => state.config.activeBase);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  let isDynamicDashboard = DYNAMIC_DASHBOARD;
  if (smallerThanLarge) {
    isDynamicDashboard = false;
  }

  const { DASHBOARDS_PARAMS_PER_BASE } = QES_CONSTANTS;
  let DASHBOARD_PARAMS;
  if (DASHBOARDS_PARAMS_PER_BASE?.[activeBaseId]) {
    DASHBOARD_PARAMS = DASHBOARDS_PARAMS_PER_BASE?.[activeBaseId];
  } else {
    DASHBOARD_PARAMS = DASHBOARDS_PARAMS_PER_BASE.default;
  }

  const {
    WIDGETS,
    COOCCURRENCES_FIELDS,
    RELATIONS_NODES_FIELDS,
    RELATIONS_LINKS_FIELDS,
  } = DASHBOARD_PARAMS;

  let cooccurrenceFields = _.map(
    COOCCURRENCES_FIELDS,
    (field, index) => ({
      ...field,
      color: entitiesColors[index],
    }),
  );

  let relationsNodesFields = _.map(
    RELATIONS_NODES_FIELDS,
    (field, index) => ({
      ...field,
      color: RELATIONS_NODES_FIELDS.length > 15 ? getRandomColor(index) : relationsEntitiesColors[index],
    }),
  );

  let relationsLinksFields = _.map(
    RELATIONS_LINKS_FIELDS,
    (field, index) => ({
      ...field,
      color: getRandomColor(index),
    }),
  );

  const getLinkColor = (linkItem) => {
    let linkColor = '#c5c5c5';
    if (theme.palette.dark === 'dark') {
      linkColor = '#dddddd';
    }
    if (linkItem) {
      if (linkItem.type === 'multipleLinks') {
        linkColor = theme.palette.mode === 'dark' ? '#ffffff' : '#000000';
      } else {
        linkColor = _.find(relationsLinksFields, { value: linkItem.type })?.color || linkColor;
      }
    }
    return linkColor;
  };

  // On utilise ici une ref pour pouvoir la mettre à jour
  // depuis le widget cooccurrences sans re-render tout le
  // dashboard
  let activeCooccurrencesFields = [];
  if (cooccurrenceFields) {
    activeCooccurrencesFields = _.map(_.filter(cooccurrenceFields, 'active'), 'value');
  }
  const cooccurrencesNodesFilterValue = useRef(activeCooccurrencesFields);
  const cooccurrencesNodesFilterValueJoined = cooccurrencesNodesFilterValue.current.join(',');

  let activeRelationsNodesFields = [];
  if (relationsNodesFields) {
    activeRelationsNodesFields = _.map(_.filter(relationsNodesFields, 'active'), 'value');
  }
  const relationsNodesFilterValue = useRef(activeRelationsNodesFields);
  let activeRelationsLinksFields = [];
  if (relationsLinksFields) {
    activeRelationsLinksFields = _.map(_.filter(relationsLinksFields, 'active'), 'value');
  }
  const relationsLinksFilterValue = useRef(activeRelationsLinksFields);
  const relationsLinksFilterValueJoined = relationsLinksFilterValue.current.join(',');

  const buildNodesTypesAdditionalQuery = () => {
    const nodesTypesSources = [];
    const nodesTypesDestinations = [];
    _.forEach(
      relationsNodesFilterValue.current,
      (nodeTypeValue) => {
        nodesTypesSources.push(
          `QES_Relation_Source_Type:${nodeTypeValue}`,
        );
        nodesTypesDestinations.push(
          `QES_Relation_Destination_Type:${nodeTypeValue}`,
        );
      },
    );
    let additionalQuery = '';
    if (nodesTypesSources) {
      additionalQuery = `(${nodesTypesSources.join(' OR ')}) AND (${nodesTypesDestinations.join(' OR ')})`;
    }
    return additionalQuery;
  };

  const widgets = _.map(
    WIDGETS,
    (widget) => {
      const finalWidget = {
        ...widget,
      };
      if (widget.i === 'relations') {
        finalWidget.relations = relationsLinksFilterValueJoined;
        finalWidget.aggregates = [relationsLinksFilterValueJoined];
        finalWidget.additionalQuery = buildNodesTypesAdditionalQuery();
      } else if (widget.i === 'cooccurrences') {
        finalWidget.facets = cooccurrencesNodesFilterValueJoined;
        finalWidget.aggregates = [cooccurrencesNodesFilterValueJoined];
      }
      return finalWidget;
    },
  );

  const handleExportWidget = (widgetId) => {
    const keepAllWidgetsData = true;
    const params = _.find(widgets, { i: widgetId });
    params.facetmax = 100000;
    params.exportWidget = true;
    dispatch(fetchWidgets(strategy, [params], false, keepAllWidgetsData));
  };

  useEffect(() => {
    if (!_.isEmpty(strategy)) {
      dispatch(fetchWidgets(strategy, widgets));
    }

    // On ne met pas cooccurrencesNodesFilterValue dans les dépendances, pour
    // éviter de refresh tout le dashboard quand on change juste
    // le type des éléments affichés (refresh géré dans
    // handleChangeCooccurrenceValue).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, strategy]);

  const renderWidget = (widgetParams) => {
    const dataGridParams = {
      x: widgetParams.x,
      y: widgetParams.y,
      w: widgetParams.w,
      h: widgetParams.h,
    };
    switch (widgetParams.type) {
      case 'spline':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  component={ColumnHisto}
                  componentProps={{ containerId: widgetParams.i }}
                  draggable={isDynamicDashboard}
                  highchartsOptions={{
                    options: {
                      tooltip: {
                        formatter: function tooltipFormatter() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${format(this.x)}: <b>${this.y}</b>`;
                        },
                      },
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: isDynamicDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                      series,
                      yAxis: {
                        type: 'logarithmic',
                        allowNegativeLog: true,
                        title: { text: '' },
                      },
                      legend: false,
                      xAxis: {
                        title: { text: '' },
                        startOnTick: true,
                        endOnTick: true,
                        gridLineWidth: 0,
                        dateTimeLabelFormats: {
                          second: '%d/%m/%Y<br/>%H:%M:%S',
                          minute: '%d/%m/%Y<br/>%H:%M',
                          hour: '%d/%m/%Y<br/>%H:%M',
                          day: '%Y<br/>%d/%m',
                          month: '%m/%Y',
                          year: '%Y',
                        },
                        events: {
                          afterSetExtremes: (event) => {
                            if (event.trigger === 'zoom') {
                              const min = series[0].data[0][0];
                              const max = series[0].data[
                                series[0].data.length - 1
                              ][0];
                              let valmin = event.min;
                              let valmax = event.max;
                              if (valmin < min) {
                                valmin = min;
                              }
                              if (valmax > max) {
                                valmax = max;
                              }
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                  formatFacet: 'date',
                                  comparator: DATE_INTERVAL_COMPARATOR.between,
                                  begin: format(new Date(valmin), 'yyyyMMdd'),
                                  end: format(new Date(valmax), 'yyyyMMdd'),
                                },
                              ]));
                            }
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'bar':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  component={Bar}
                  componentProps={{ containerId: widgetParams.i }}
                  draggable={isDynamicDashboard}
                  handleExportWidget={() => handleExportWidget(widgetParams.i)}
                  highchartsOptions={{
                    options: {
                      tooltip: {
                        enabled: false,
                      },
                      plotOptions: {
                        series: {
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.name,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                            },
                          },
                        },
                      },
                      legend: false,
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: isDynamicDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                      yAxis: {
                        title: {
                          text: '',
                        },
                      },
                      series,
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'map':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  component={Map}
                  componentProps={{ containerId: widgetParams.i }}
                  draggable={isDynamicDashboard}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        series: {
                          tooltip: {
                            pointFormatter: function getTranslatedCountryName() {
                              // eslint-disable-next-line react/no-this-in-sfc
                              return `${t(`dashboard.countries.${this['iso-a2']}`)}: <b>${this.value}</b>`;
                            },
                          },
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.options.name,
                                    dontQuoteStrategy: true,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                            },
                          },
                        },
                      },
                      series,
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: isDynamicDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'sunburst':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  component={Sunburst}
                  componentProps={{ containerId: widgetParams.i }}
                  draggable={isDynamicDashboard}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        sunburst: {
                          point: {
                            events: {
                              click: (event) => {
                                const interrogationArray = _.map(additionalData.facets.split(','), _.trim);
                                if (event.point.name && event.point.node.isLeaf) {
                                  dispatch(refineAddFacetsValues([
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[1],
                                      ),
                                      strategie: event.point.name,
                                    },
                                  ], (event.ctrlKey || event.metaKey)));
                                } else if (event.shiftKey && event.point.node.index !== 0) {
                                  dispatch(refineAddFacetsValues([
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[0],
                                      ),
                                      strategie: event.point.node.id,
                                      dontQuoteStrategy: event.point.node.id.indexOf(' ') === -1,
                                    },
                                  ], (event.ctrlKey || event.metaKey)));
                                }
                              },
                            },
                          },
                        },
                      },
                      series: [{
                        data: _.get(series, '[0].data', []),
                      }],
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: isDynamicDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'documentlist':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series }) => (
                <DashboardWidget
                  component={DocumentList}
                  componentProps={{
                    title: {
                      text: computeTitle(widgetParams.title, series),
                      align: isDynamicDashboard ? 'center' : 'left',
                      margin: 20,
                    },
                    qesdocuments: _.get(series, '[0]', []),
                    handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, activeBaseId)),
                    handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, activeBaseId)),
                    containerId: widgetParams.i,
                  }}
                  draggable={isDynamicDashboard}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'pie':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series, additionalData }) => {
                let data = _.get(series, '[0].data', []);
                if (widgetParams.tones) {
                  data = _.sortBy(_.get(series, '[0].data', []), 'name').map(
                    (item) => ({
                      ...item,
                      color: getToneColor(item.name),
                    }),
                  );
                }
                const additionnalOptions = {};
                if (widgetParams.monoColors) {
                  additionnalOptions.colors = monoColors(0.35, _.get(series, '[0].data.length', 15), 0);
                }
                return (
                  <DashboardWidget
                    component={Pie}
                    componentProps={{ containerId: widgetParams.i }}
                    draggable={isDynamicDashboard}
                    handleExportWidget={() => handleExportWidget(widgetParams.i)}
                    highchartsOptions={{
                      options: {
                        plotOptions: {
                          pie: {
                            data,
                            point: {
                              events: {
                                click: (event) => {
                                  dispatch(refineAddFacetsValues([
                                    {
                                      champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                      strategie: event.point.strategy,
                                    },
                                  ], (event.ctrlKey || event.metaKey)));
                                },
                              },
                            },
                          },
                        },
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: isDynamicDashboard ? 'center' : 'left',
                          margin: 20,
                        },
                        ...additionnalOptions,
                      },
                    }}
                  />
                );
              }}
            </WidgetContainer>
          </div>
        );
      case 'solidgauge':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({
                series,
                axisX,
                additionalData,
              }) => (
                <DashboardWidget
                  component={SolidGauge}
                  componentProps={{ containerId: widgetParams.i }}
                  draggable={isDynamicDashboard}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        solidgauge: {
                          events: {
                            click: (event) => {
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                  strategie: event.point.name,
                                  dontQuoteStrategy: event.point.name.indexOf(' ') === -1,
                                },
                              ], (event.ctrlKey || event.metaKey)));
                            },
                          },
                        },
                      },
                      pane: {
                        background: axisX.categories.map((aggregate, index) => ({
                          outerRadius: aggregate.outerRadius,
                          innerRadius: aggregate.innerRadius,
                          borderWidth: 0,
                          backgroundColor: `${Highcharts.getOptions().colors[9 - (index * 2)]}4D`,
                        })),
                      },
                      tooltip: {
                        enabled: false,
                      },
                      legend: {
                        align: 'center',
                        labelFormatter: function lf() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${this.name} - ${this.data[0].y}%`;
                        },
                      },
                      series: _.get(series, '[0].data', []).map((aggregate, index) => ({
                        name: aggregate.name,
                        data: aggregate.data.map((aggdata) => ({
                          radius: aggdata.radius,
                          innerRadius: aggdata.innerRadius,
                          name: aggdata.name,
                          y: aggdata.y,
                          color: Highcharts.getOptions().colors[9 - (index * 2)],
                        })),
                        color: Highcharts.getOptions().colors[9 - (index * 2)],
                        showInLegend: true,
                      })),
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: isDynamicDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'sankey':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  component={Sankey}
                  componentProps={{ containerId: widgetParams.i }}
                  draggable={isDynamicDashboard}
                  handleExportWidget={() => handleExportWidget(widgetParams.i)}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        series: {
                          point: {
                            events: {
                              click: (event) => {
                                const interrogationArray = _.map(additionalData.facets.split(','), _.trim);
                                let conceptsPersonsFields;
                                if (event.point.from) {
                                  // On a cliqué sur un lien, donc on prépare le double raffinage
                                  conceptsPersonsFields = [
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[event.point.fromNode.column],
                                      ),
                                      strategie: event.point.fromNode.name,
                                    },
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[event.point.toNode.column],
                                      ),
                                      strategie: event.point.toNode.name,
                                    },
                                  ];
                                } else {
                                  // On a cliqué sur un noeud, on fait un simple raffinage
                                  conceptsPersonsFields = [
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[event.point.column],
                                      ),
                                      strategie: event.point.name,
                                    },
                                  ];
                                }
                                dispatch(refineAddFacetsValues(
                                  conceptsPersonsFields,
                                  (event.ctrlKey || event.metaKey),
                                ));
                              },
                            },
                          },
                        },
                      },
                      series,
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: isDynamicDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'treemap':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  component={Treemap}
                  componentProps={{ containerId: widgetParams.i }}
                  draggable={isDynamicDashboard}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        treemap: {
                          animation: false,
                          colorByPoint: true,
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.name,
                                    dontQuoteStrategy: true,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                            },
                          },
                        },
                      },
                      series,
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: isDynamicDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'networkgraph':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, isResizable: false }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {widgetParams.i === 'cooccurrences' ? (
                ({ series, additionalData }) => {
                  cooccurrenceFields = cooccurrenceFields.map(
                    (cooccurrenceField) => ({
                      ...cooccurrenceField,
                      nbNodes: _.get(additionalData.nodeCounts, cooccurrenceField.value, 0),
                    }),
                  );
                  return (
                    <DashboardWidget
                      component={NetworkGraph}
                      componentProps={{
                        containerId: widgetParams.i,
                        nodesFilterValue: cooccurrencesNodesFilterValue.current,
                        nodesFilterItems: cooccurrenceFields,
                        handleRefreshChart: (filterValues) => {
                          if (_.isEmpty(filterValues)) {
                            snackActions.warning(t('dashboard.widget.at_least_or_node'));
                            return;
                          }
                          cooccurrencesNodesFilterValue.current = filterValues;
                          const keepOtherWidgetsData = true;
                          const joinedValue = filterValues.join(',');
                          dispatch(fetchWidgets(strategy, [
                            {
                              i: 'cooccurrences',
                              facets: joinedValue,
                              aggregates: [joinedValue],
                              facetmax: 200,
                              facetmax2: 5,
                              mindoccount: 1,
                              type: 'networkgraph',
                            },
                          ], keepOtherWidgetsData));
                        },
                        handleLinkClick: (event, fromNode, toNode) => {
                          const fromIdField = getFieldIdFromFormFields(fields, fromNode.options.group);
                          const toIdField = getFieldIdFromFormFields(fields, toNode.options.group);
                          const facetsValues = [{
                            champ: fromIdField,
                            strategie: fromNode.id,
                          }];
                          if (fromIdField === toIdField) {
                            // Si jamais l'ID de champ est le même pour les deux noeuds,
                            // on colle les deux valeurs dans un même tableau, qui sera
                            // découpé convenablement au moment du fetch des résultats
                            facetsValues[0].strategie = [fromNode.id, toNode.id];
                          } else {
                            // A l'inverse, si les ID de champs sont différents, on
                            // on ajoute une seconde "facetValue" avec les éléments
                            // du second noeud
                            facetsValues.push({
                              champ: toIdField,
                              strategie: toNode.id,
                            });
                          }
                          dispatch(refineAddFacetsValues(facetsValues, (event.ctrlKey || event.metaKey)));
                        },
                      }}
                      draggable={isDynamicDashboard}
                      highchartsOptions={{
                        options: {
                          chart: {
                            height: 720,
                          },
                          plotOptions: {
                            series: {
                              point: {
                                events: {
                                  click: (event) => {
                                    dispatch(refineAddFacetsValues([
                                      {
                                        champ: getFieldIdFromFormFields(fields, event.point.options.group),
                                        strategie: event.point.name,
                                      },
                                    ], (event.ctrlKey || event.metaKey)));
                                  },
                                },
                              },
                            },
                          },
                          series: [{
                            nodes: _.get(series, '[0].nodes', []).map(
                              (node) => ({
                                ...node,
                                color: _.find(cooccurrenceFields, { value: node.group })?.color,
                              }),
                            ),
                            data: _.get(series, '[0].data', []).map(
                              (link) => ({
                                ...link,
                                color: getLinkColor(),
                              }),
                            ),
                          }],
                          title: {
                            text: computeTitle(widgetParams.title, series),
                            align: isDynamicDashboard ? 'center' : 'left',
                            margin: 20,
                          },
                        },
                      }}
                    />
                  );
                }
              ) : (
                ({ series, additionalData }) => {
                  relationsLinksFields = relationsLinksFields.map(
                    (relationsLinksField) => ({
                      ...relationsLinksField,
                      nbLinks: _.get(additionalData.linkCounts, relationsLinksField.value, 0),
                    }),
                  );
                  relationsNodesFields = relationsNodesFields.map(
                    (relationsNodesField) => ({
                      ...relationsNodesField,
                      nbNodes: _.get(additionalData.nodeCounts, relationsNodesField.value, 0),
                    }),
                  );
                  return (
                    <DashboardWidget
                      component={NetworkGraph}
                      componentProps={{
                        iterations: additionalData.iterations,
                        withExploration: widgetParams.withExploration,
                        linksFilterValue: relationsLinksFilterValue.current,
                        linksFilterItems: _.orderBy(
                          relationsLinksFields,
                          ['nbLinks', (item) => relationsLinksFilterValue.current.indexOf(item.value) > -1, 'name'],
                          ['desc', 'desc', 'asc'],
                        ),
                        nodesFilterValue: relationsNodesFilterValue.current,
                        nodesFilterItems: _.orderBy(
                          relationsNodesFields,
                          ['nbNodes', 'name'],
                          ['desc', 'asc'],
                        ),
                        handleRefreshChart: (nodesTypesValues, linksTypesValues) => {
                          if (_.isEmpty(nodesTypesValues) || _.isEmpty(linksTypesValues)) {
                            snackActions.warning(t('dashboard.widget.at_least_one_link_or_node'));
                            return;
                          }
                          relationsNodesFilterValue.current = nodesTypesValues;
                          relationsLinksFilterValue.current = linksTypesValues;
                          const joinedTypesValue = linksTypesValues.join(',');

                          const keepOtherWidgetsData = true;
                          dispatch(fetchWidgets(
                            strategy,
                            [
                              {
                                i: 'relations',
                                facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
                                relations: joinedTypesValue,
                                aggregates: [joinedTypesValue],
                                facetmax: 200,
                                facetmax2: 5,
                                mindoccount: 1,
                                additionalQuery: buildNodesTypesAdditionalQuery(),
                                type: 'networkgraph',
                              },
                            ],
                            keepOtherWidgetsData,
                          ));
                        },
                        handleLinkClick: (event, fromNode, toNode, link) => {
                          const fromIdField = getFieldIdFromFormFields(fields, `QES_${fromNode.options.group}`);
                          const toIdField = getFieldIdFromFormFields(fields, `QES_${toNode.options.group}`);
                          const facetsValues = [{
                            champ: fromIdField,
                            strategie: fromNode.id,
                          }];
                          // Si jamais on clique sur un lien découvert par l'exploration,
                          // on va repartir d'une recherche vide, donc on refresh tout
                          const refreshFields = {
                            [`F_${fromIdField}`]: `"${_.trim(fromNode.id, ' "')}"`,
                          };
                          if (fromIdField === toIdField) {
                            // Si jamais l'ID de champ est le même pour les deux noeuds,
                            // on colle les deux valeurs dans un même tableau, qui sera
                            // découpé convenablement au moment du fetch des résultats
                            facetsValues[0].strategie = [fromNode.id, toNode.id];
                            refreshFields[`F_${fromIdField}`] = (
                              `"${_.trim(fromNode.id, ' "')}" AND "${_.trim(toNode.id, ' "')}"`
                            );
                          } else {
                            // A l'inverse, si les ID de champs sont différents, on
                            // on ajoute une seconde "facetValue" avec les éléments
                            // du second noeud
                            facetsValues.push({
                              champ: toIdField,
                              strategie: toNode.id,
                            });
                            refreshFields[`F_${toIdField}`] = `"${_.trim(toNode.id, ' "')}"`;
                          }
                          if (link.options.iteration) {
                            if (link.options.relatedLinks) {
                              relationsLinksFilterValue.current = [...new Set(
                                _.concat(
                                  relationsLinksFilterValue.current,
                                  _.map(link.options.relatedLinks, 'type'),
                                ),
                              )];
                            } else if (!relationsLinksFilterValue.current.includes(link.options.type)) {
                              relationsLinksFilterValue.current.push(link.options.type);
                            }
                            let additionnalFetchParams = {
                              refreshForm: true,
                              clearSelection: true,
                              clearResults: true,
                            };
                            if (event.ctrlKey || event.metaKey) {
                              additionnalFetchParams = {
                                quickResultsScope: true,
                              };
                            } else {
                              dispatch(setDashboardLoading());
                            }
                            dispatch(fetchResults({
                              bodyItems: {
                                premier: 1,
                                dernier: 50,
                                base: activeBaseId,
                                champs: refreshFields,
                              },
                              ...additionnalFetchParams,
                            }));
                          } else {
                            dispatch(refineAddFacetsValues(facetsValues, (event.ctrlKey || event.metaKey)));
                          }
                        },
                      }}
                      draggable={isDynamicDashboard}
                      highchartsOptions={{
                        options: {
                          chart: {
                            height: 720,
                          },
                          plotOptions: {
                            series: {
                              point: {
                                events: {
                                  click: (event) => {
                                    const idField = getFieldIdFromFormFields(
                                      fields,
                                      `QES_${event.point.options.group}`,
                                    );
                                    if (widgetParams.withExploration && event.shiftKey) {
                                      const joinedTypesValue = relationsLinksFields.map(
                                        (field) => field.value,
                                      ).join(',');
                                      const keepAllWidgetsData = true;
                                      dispatch(fetchWidgets(
                                        `QES_Relation_Source_Text.verbatim:"${_.trim(event.point.name, ' "')}"`,
                                        [
                                          {
                                            i: 'relations',
                                            // eslint-disable-next-line max-len
                                            facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
                                            relations: joinedTypesValue,
                                            aggregates: [joinedTypesValue],
                                            facetmax: 200,
                                            facetmax2: 5,
                                            mindoccount: 1,
                                            additionalQuery: buildNodesTypesAdditionalQuery(),
                                            type: 'networkgraph',
                                          },
                                        ],
                                        false,
                                        keepAllWidgetsData,
                                        event.point.name,
                                      ));
                                    } else if (event.point.options.iteration) {
                                      let additionnalFetchParams = {
                                        refreshForm: true,
                                        clearSelection: true,
                                        clearResults: true,
                                      };
                                      if (event.ctrlKey || event.metaKey) {
                                        additionnalFetchParams = {
                                          quickResultsScope: true,
                                        };
                                      } else {
                                        dispatch(setDashboardLoading());
                                      }
                                      dispatch(fetchResults({
                                        bodyItems: {
                                          premier: 1,
                                          dernier: 50,
                                          base: activeBaseId,
                                          champs: {
                                            [`F_${idField}`]: `"${_.trim(event.point.name, ' "')}"`,
                                          },
                                        },
                                        ...additionnalFetchParams,
                                      }));
                                    } else {
                                      dispatch(refineAddFacetsValues([
                                        {
                                          champ: idField,
                                          strategie: event.point.name,
                                        },
                                      ], (event.ctrlKey || event.metaKey)));
                                    }
                                  },
                                },
                              },
                            },
                          },
                          series: [{
                            nodes: _.get(series, '[0].nodes', []).map(
                              (node) => ({
                                ...node,
                                color: _.find(relationsNodesFields, { value: node.group })?.color,
                              }),
                            ),
                            data: _.get(series, '[0].data', []).map(
                              (link) => ({
                                ...link,
                                color: getLinkColor(link),
                              }),
                            ),
                          }],
                          title: {
                            text: computeTitle(widgetParams.title, series),
                            align: isDynamicDashboard ? 'center' : 'left',
                            margin: 20,
                          },
                        },
                      }}
                    />
                  );
                }
              )}
            </WidgetContainer>
          </div>
        );
      case 'wordcloud':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  component={Wordcloud}
                  componentProps={{ containerId: widgetParams.i }}
                  draggable={isDynamicDashboard}
                  handleExportWidget={() => handleExportWidget(widgetParams.i)}
                  highchartsOptions={{
                    options: {
                      tooltip: {
                        formatter: function tooltipFormatter() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${this.key}: <b>${this.point.weight}</b>`;
                        },
                      },
                      plotOptions: {
                        wordcloud: {
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.options.name,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                            },
                          },
                        },
                      },
                      series: [{
                        data: _.map(_.get(series, '[0].data', []), ([word, weight]) => ({
                          name: word,
                          weight,
                          color: getColorForFrequency(
                            theme.palette.mode === 'dark' ? 0.55 : 0.35,
                            series[0].minFreq,
                            series[0].maxFreq,
                            weight,
                            0,
                            theme.palette.mode === 'dark' ? '#ffffff' : null,
                          ),
                        })),
                        name: widgetParams.title,
                      }],
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: isDynamicDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'datatable':
        return (
          <div key={widgetParams.i} id={widgetParams.i} data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}>
            <WidgetContainer widgetId={widgetParams.i}>
              {({ series, additionalData }) => {
                const firstColumn = { name: '' };
                const additionnalOptions = {};
                if (widgetParams.computeLink) {
                  firstColumn.cellRenderer = generateDatatableFirstCell(
                    dispatch,
                    fields,
                    additionalData.facets,
                    widgetParams.computeLink,
                  );
                } else {
                  additionnalOptions.onRowClick = (event, rowData) => dispatch(refineAddFacetsValues([
                    {
                      champ: getFieldIdFromFormFields(fields, additionalData.facets),
                      strategie: rowData[0],
                    },
                  ], (event.ctrlKey || event.metaKey)));
                }
                return (
                  <DashboardWidget
                    component={Datatable}
                    componentProps={{
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: isDynamicDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                      columns: [
                        firstColumn,
                        { name: t('dashboard.widget.count'), align: 'right' },
                      ],
                      ...additionnalOptions,
                      data: _.get(series, '[0].data', []),
                    }}
                    draggable={isDynamicDashboard}
                    handleExportWidget={() => handleExportWidget(widgetParams.i)}
                  />
                );
              }}
            </WidgetContainer>
          </div>
        );
      default:
        return () => _.noop;
    }
  };

  return (
    <Box paddingBottom="40px" overflow="auto">
      <ResponsiveGridLayout
        className="layout"
        style={{ overflow: 'hidden', overflowX: 'hidden' }}
        isDraggable={isDynamicDashboard}
        isResizable={isDynamicDashboard}
        breakpoints={{ lg: 1200, md: 900 }}
        cols={{ lg: 6, md: 2 }}
        rowHeight={380}
        draggableHandle=".drag-handle"
      >
        {_.map(
          widgets,
          (widgetParams) => (
            renderWidget(widgetParams)
          ),
        )}
      </ResponsiveGridLayout>
    </Box>
  );
};

export default React.memo(DashboardChartsContainer);

const fr = {
  fr: {
    translation: {
      dashboard: {
        widget: {
          level1: 'Type de procédures I',
          level2: 'Type de procédures II',
          level2_by_level1: 'Type de procédures II par type de procédures I',
          email: 'Emails',
          phone: 'Téléphones',
          address: 'Adresses',
          countries_mentioned: 'Carte',
          location: 'Localisations',
          country: 'Pays',
          city: 'Villes',
          region: 'Régions',
          object: 'Objets',
          proces_verbal: 'Procès Verbaux',
          law_article: 'Articles Lois',
          natinf: 'Codes Natinf',
          pv_type: 'Types de PV',
          nature_infraction: 'Natures de l\'infraction',
          qualification_infraction: 'Qualifications de l\'infraction',
          lancelot_faits: 'Ontologie Faits',
          lancelot_arme: 'Ontologie Armes',
          lancelot_curatelle: 'Ontologie Curatelle',
          lancelot_langues: 'Ontologie Langues',
          lancelot_scelles: 'Ontologie Scellés',
          lancelot_stupefiants: 'Ontologie Stupéfiants',
          sealNumber_sealObject: 'Numéro de Scellé, Objet de Scellé',
          persons_objects_concepts: 'Personnes, Objets, Concept Risques',
          person_victim: 'Noms Victimes',
          person_mec: 'Noms MEC',
          persons_and_procedures: 'Personnes et Procédures',
          top_50_sentence_part: 'Phrases de relations les plus fréquences',
          top_50_text_part: 'Relations les plus fréquentes',
        },
      },
      document: {
        number_PV: 'N° PV : ',
        number_natinf: 'N° Natinf : ',
        number_article_law: 'N° Article de loi : ',
        nature_infraction: 'Nature de l\'infraction : ',
        qualification_infraction: 'Qualification de l\'infraction : ',
      },
    },
  },
};

export default fr;

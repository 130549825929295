import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import {
  AccountBalance,
  Place,
  AccountCircle,
} from '@mui/icons-material';
import ExpandableTagBox from 'generic/components/ui/ExpandableTagBox';
import { overrideRessource } from 'generic/utils/utils';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import { useTranslation } from 'react-i18next';
import { documentPropType } from 'custom/lancelot/core/qes/documentProptypes';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const Document = ({
  disableTagsRefine,
  displayTitle,
  document,
  handleGetDocumentComplete,
  isCompleteDisplay,
}) => {
  const { t } = useTranslation();
  const currentTheme = useColorSchemeDetector();
  const { relationsEntitiesColors } = currentTheme.HIGHCHARTS;

  const {
    lawArticle,
    attachment,
    date,
    natinfId,
    infractionNature,
    pvId,
    pvType,
    infractionQualification,
    sentences,
    snippet,
    text,
    title,
  } = useDocumentFields(document);

  const { qesdocument } = document;

  return (
    <Box display="block" width="100%" sx={{ wordBreak: 'break-word' }}>
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          fontSize="1.2rem"
          margin="6px 0"
          display="block"
        >
          <Box
            component="span"
            onClick={handleGetDocumentComplete}
            color="text.primary"
            sx={{ cursor: 'pointer' }}
          >
            {pvType || title}
          </Box>
        </Typography>
      )}
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow="1">
          <Box lineHeight="1.3rem">
            <Box
              display="flex"
              flexWrap="wrap"
              mb={0.5}
            >
              {date && (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.neutral"
                >
                  {date}
                </Typography>
              )}
              {pvId && (
                <Fragment>
                  <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t('document.number_PV')}
                    {pvId}
                  </Typography>
                </Fragment>
              )}
              {natinfId && (
                <Fragment>
                  <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t('document.number_natinf')}
                    {natinfId}
                  </Typography>
                </Fragment>
              )}
              {lawArticle && (
                <Fragment>
                  <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t('document.number_article_law')}
                    {lawArticle}
                  </Typography>
                </Fragment>
              )}
              {infractionNature && (
                <Fragment>
                  <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t('document.nature_infraction')}
                    {infractionNature}
                  </Typography>
                </Fragment>
              )}
              {infractionQualification && (
                <Fragment>
                  <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t('document.qualification_infraction')}
                    {infractionQualification}
                  </Typography>
                </Fragment>
              )}
              {attachment && (
                <Fragment>
                  <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                  <Link
                    href={attachment}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      PV
                    </Typography>
                  </Link>
                </Fragment>
              )}
            </Box>

            {sentences && (
              sentences.map((sentence) => (
                <Box p={1} mt={0.5} mb={1} borderLeft="5px solid #d3d3d3" whiteSpace="pre-wrap" lineHeight="22px">
                  {sentence}
                </Box>
              ))
            )}
            {!isCompleteDisplay && (
              <div>
                {snippet}
              </div>
            )}
            <ExpandableTagBox
              disableRefine={disableTagsRefine}
              qesdocument={qesdocument}
              tags={[
                { fieldName: 'QES_Company', icon: AccountBalance, fieldId: 1032000431 },
                { fieldName: 'QES_Person', icon: AccountCircle, fieldId: 901 },
                { fieldName: 'QES_Product', icon: Place, fieldId: 24 },
                { fieldName: 'QES_Concept', fieldId: 30800115 },
              ].map((field, index) => ({
                ...field,
                color: relationsEntitiesColors[index],
              }))}
            />
            {isCompleteDisplay && (
              <Box mt={1} mb={2} whiteSpace="pre-wrap" sx={{ clear: 'both' }}>
                {text}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Document.propTypes = {
  disableTagsRefine: PropTypes.bool,
  displayTitle: PropTypes.bool,
  // description du document QES attendu pour ce client
  document: documentPropType,
  handleGetDocumentComplete: PropTypes.func,
  isCompleteDisplay: PropTypes.bool,
};

Document.defaultProps = {
  disableTagsRefine: false,
  displayTitle: true,
  document: {},
  handleGetDocumentComplete: null,
  isCompleteDisplay: false,
};

export default Document;

import i18next from 'i18next';

export default {
  DEFAULT_SEARCH_PER_BASE: {
    22801208: {
      F_2: '*',
    },
  },
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: false,

  override_all: {
    DASHBOARDS_PARAMS_PER_BASE: {
      default: {
        WIDGETS: [
          {
            i: 'publicationDates',
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            facets: 'Date_creation#auto_date_histogram|300 _key:asc',
            aggregates: ['agg_Date_creation'],
            type: 'spline',
            title: i18next.t('dashboard.widget.documents_published_by_dates'),
          },
          {
            i: 'level1Level2',
            x: 0,
            y: 1,
            w: 1.5,
            h: 1,
            facets: 'Level1, Level2',
            aggregates: ['agg_Level1'],
            pivots: ['agg_Level2'],
            type: 'sunburst',
            title: i18next.t('dashboard.widget.level2_by_level1'),
          },
          {
            i: 'levelGauge1',
            x: 1.5,
            y: 1,
            w: 1.5,
            h: 1,
            facets: 'Level1',
            aggregates: ['agg_Level1'],
            type: 'solidgauge',
            title: i18next.t('dashboard.widget.level1'),
          },
          {
            i: 'level1',
            x: 3,
            y: 1,
            w: 1.5,
            h: 1,
            facets: 'Level1',
            aggregates: ['agg_Level1'],
            type: 'pie',
            title: i18next.t('dashboard.widget.level1'),
          },
          {
            i: 'level2',
            x: 4.5,
            y: 1,
            w: 1.5,
            h: 1,
            facets: 'Level2',
            aggregates: ['agg_Level2'],
            type: 'pie',
            title: i18next.t('dashboard.widget.level2'),
          },
          {
            i: 'email',
            x: 0,
            y: 2,
            w: 2,
            h: 1,
            facets: 'QES_Email.verbatim',
            aggregates: ['agg_QES_Email.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.email'),
          },
          {
            i: 'phone',
            x: 2,
            y: 2,
            w: 2,
            h: 1,
            facets: 'QES_Phone.verbatim',
            aggregates: ['agg_QES_Phone.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.phone'),
          },
          {
            i: 'address',
            x: 4,
            y: 2,
            w: 2,
            h: 1,
            facets: 'QES_Address.verbatim',
            aggregates: ['agg_QES_Address.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.address'),
          },
          {
            i: 'countryTopic',
            x: 0,
            y: 4,
            w: 3,
            h: 2,
            facets: 'QES_CountryTopicCode.verbatim',
            aggregates: ['agg_QES_CountryTopicCode.verbatim'],
            type: 'map',
            mindoccount: 1,
            facetmax: 10000,
            facetmax2: 10000,
            title: i18next.t('dashboard.widget.countries_mentioned'),
          },
          {
            i: 'location',
            x: 3,
            y: 4,
            w: 1.5,
            h: 1,
            facets: 'QES_Location.verbatim',
            aggregates: ['agg_QES_Location.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            title: i18next.t('dashboard.widget.location'),
          },
          {
            i: 'country',
            x: 4.5,
            y: 4,
            w: 1.5,
            h: 1,
            facets: 'QES_Country.verbatim',
            aggregates: ['agg_QES_Country.verbatim'],
            type: 'bar',
            title: i18next.t('dashboard.widget.country'),
          },
          {
            i: 'city',
            x: 3,
            y: 5,
            w: 1.5,
            h: 1,
            facets: 'QES_City.verbatim',
            aggregates: ['agg_QES_City.verbatim'],
            type: 'bar',
            title: i18next.t('dashboard.widget.city'),
          },
          {
            i: 'region',
            x: 4.5,
            y: 5,
            w: 1.5,
            h: 1,
            facets: 'QES_Region.verbatim',
            aggregates: ['agg_QES_Region.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            title: i18next.t('dashboard.widget.region'),
          },
          {
            i: 'object',
            x: 0,
            y: 6,
            w: 6,
            h: 1,
            facets: 'QES_Object.verbatim',
            aggregates: ['agg_QES_Object.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.object'),
          },
          {
            i: 'personsProcedures',
            x: 0,
            y: 7,
            w: 3,
            h: 1,
            facets: 'QES_Person.verbatim, QES_ConceptProcedures.verbatim',
            aggregates: ['agg_QES_Person.verbatim'],
            pivots: ['agg_QES_ConceptProcedures.verbatim'],
            facetmax: 5,
            facetmax2: 10,
            mindoccount: 1,
            type: 'sankey',
            title: i18next.t('dashboard.widget.persons_and_procedures'),
          },
          {
            i: 'personsObjectsRisks',
            x: 3,
            y: 7,
            w: 3,
            h: 1,
            facets: 'QES_Person.verbatim, QES_Object.verbatim, QES_ConceptRisk.verbatim',
            aggregates: ['agg_QES_Person.verbatim'],
            facetmax: 15,
            facetmax2: 3,
            mindoccount: 1,
            type: 'sankey',
            title: i18next.t('dashboard.widget.persons_objects_concepts'),
          },
          {
            i: 'procesVerbal',
            x: 0,
            y: 8,
            w: 1.5,
            h: 1,
            facets: 'QES_ProcesVerbal.verbatim',
            aggregates: ['agg_QES_ProcesVerbal.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.proces_verbal'),
          },
          {
            i: 'lawArticle',
            x: 1.5,
            y: 8,
            w: 1.5,
            h: 1,
            facets: 'QES_ArticleLoi.verbatim',
            aggregates: ['agg_QES_ArticleLoi.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.law_article'),
          },
          {
            i: 'natinf',
            x: 3,
            y: 8,
            w: 1.5,
            h: 1,
            facets: 'QES_IdNatinf.verbatim',
            aggregates: ['agg_QES_IdNatinf.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.natinf'),
          },
          {
            i: 'pvType',
            x: 4.5,
            y: 8,
            w: 1.5,
            h: 1,
            facets: 'QES_PV_Type.verbatim',
            aggregates: ['agg_QES_PV_Type.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.pv_type'),
          },
          {
            i: 'natureInfraction',
            x: 0,
            y: 9,
            w: 2,
            h: 1,
            facets: 'QES_NatureInfraction.verbatim',
            aggregates: ['agg_QES_NatureInfraction.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.nature_infraction'),
          },
          {
            i: 'qualificationInfraction',
            x: 2,
            y: 9,
            w: 2,
            h: 1,
            facets: 'QES_QualificationInfraction.verbatim',
            aggregates: ['agg_QES_QualificationInfraction.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.qualification_infraction'),
          },
          {
            i: 'LancelotFaits',
            x: 4,
            y: 9,
            w: 2,
            h: 1,
            facets: 'QES_LancelotFaits.verbatim',
            aggregates: ['agg_QES_LancelotFaits.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.lancelot_faits'),
          },
          {
            i: 'LancelotArme',
            x: 0,
            y: 10,
            w: 2,
            h: 1,
            facets: 'QES_LancelotArme.verbatim',
            aggregates: ['agg_QES_LancelotArme.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.lancelot_arme'),
          },
          {
            i: 'LancelotCuratelle',
            x: 2,
            y: 10,
            w: 2,
            h: 1,
            facets: 'QES_LancelotCuratelle.verbatim',
            aggregates: ['agg_QES_LancelotCuratelle.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.lancelot_curatelle'),
          },
          {
            i: 'LancelotLangues',
            x: 4,
            y: 10,
            w: 2,
            h: 1,
            facets: 'QES_LancelotLangues.verbatim',
            aggregates: ['agg_QES_LancelotLangues.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.lancelot_langues'),
          },
          {
            i: 'LancelotScelles',
            x: 0,
            y: 11,
            w: 2,
            h: 1,
            facets: 'QES_LancelotScelles.verbatim',
            aggregates: ['agg_QES_LancelotScelles.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.lancelot_scelles'),
          },
          {
            i: 'LancelotStupefiants',
            x: 2,
            y: 11,
            w: 2,
            h: 1,
            facets: 'QES_LancelotStupefiants.verbatim',
            aggregates: ['agg_QES_LancelotStupefiants.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.lancelot_stupefiants'),
          },
          {
            i: 'sealNumbersealObject',
            x: 4,
            y: 11,
            w: 2,
            h: 1,
            facets: 'QES_SealNumber.verbatim, QES_SealObject.verbatim',
            aggregates: ['agg_QES_SealNumber.verbatim'],
            facetmax: 15,
            facetmax2: 3,
            mindoccount: 1,
            type: 'sankey',
            title: i18next.t('dashboard.widget.sealNumber_sealObject'),
          },
          {
            i: 'LancelotMec',
            x: 0,
            y: 12,
            w: 3,
            h: 1,
            facets: 'QES_PersonMec.verbatim',
            aggregates: ['agg_QES_PersonMec.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.person_mec'),
          },
          {
            i: 'LancelotVictim',
            x: 3,
            y: 12,
            w: 3,
            h: 1,
            facets: 'QES_PersonVictim.verbatim',
            aggregates: ['agg_QES_PersonVictim.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.person_victim'),
          },
          {
            i: 'cooccurrences',
            x: 0,
            y: 14,
            w: 6,
            h: 2,
            facetmax: 200,
            facetmax2: 5,
            mindoccount: 1,
            type: 'networkgraph',
            title: i18next.t('dashboard.widget.cooccurrences_graph'),
          },
        ],

        COOCCURRENCES_FIELDS: [
          { name: 'Sociétés', value: 'QES_Company.verbatim', active: false },
          { name: 'Objets', value: 'QES_Object.verbatim', active: true },
          { name: 'Ontologie Faits', value: 'QES_LancelotFaits.verbatim', active: false },
          { name: 'Ontologie Armes', value: 'QES_LancelotArme.verbatim', active: false },
          { name: 'Ontologie Curatelle', value: 'QES_LancelotCuratelle.verbatim', active: false },
          { name: 'Ontologie Langues', value: 'QES_LancelotLangues.verbatim', active: false },
          { name: 'Ontologie Scellés', value: 'QES_LancelotScelles.verbatim', active: false },
          { name: 'Ontologie Stupéfiants', value: 'QES_LancelotStupefiants.verbatim', active: false },
        ],
      },
      22801208: {
        WIDGETS: [
          {
            i: 'level1Level2',
            x: 0,
            y: 0,
            w: 1.5,
            h: 1,
            facets: 'Level1, Level2',
            aggregates: ['agg_Level1'],
            pivots: ['agg_Level2'],
            type: 'sunburst',
            title: i18next.t('dashboard.widget.level2_by_level1'),
          },
          {
            i: 'levelGauge1',
            x: 1.5,
            y: 0,
            w: 1.5,
            h: 1,
            facets: 'Level1',
            aggregates: ['agg_Level1'],
            type: 'solidgauge',
            title: i18next.t('dashboard.widget.level1'),
          },
          {
            i: 'level1',
            x: 3,
            y: 0,
            w: 1.5,
            h: 1,
            facets: 'Level1',
            aggregates: ['agg_Level1'],
            type: 'pie',
            title: i18next.t('dashboard.widget.level1'),
          },
          {
            i: 'level2',
            x: 4.5,
            y: 0,
            w: 1.5,
            h: 1,
            facets: 'Level2',
            aggregates: ['agg_Level2'],
            type: 'pie',
            title: i18next.t('dashboard.widget.level2'),
          },
          {
            i: 'relationsSources',
            x: 0,
            y: 1,
            w: 2,
            h: 1,
            facets: 'QES_Relation_Source_Type.verbatim',
            aggregates: ['agg_QES_Relation_Source_Type.verbatim'],
            type: 'pie',
            title: i18next.t('dashboard.widget.part_left_type'),
          },
          {
            i: 'categories',
            x: 2,
            y: 1,
            w: 2,
            h: 1,
            facets: 'QES_Relation_Category.verbatim',
            aggregates: ['agg_QES_Relation_Category.verbatim'],
            type: 'treemap',
            facetmax: 20,
            title: i18next.t('dashboard.widget.relation_type'),
          },
          {
            i: 'relationsDestinations',
            x: 4,
            y: 1,
            w: 2,
            h: 1,
            facets: 'QES_Relation_Destination_Type.verbatim',
            aggregates: ['agg_QES_Relation_Destination_Type.verbatim'],
            type: 'pie',
            title: i18next.t('dashboard.widget.part_right_type'),
          },
          {
            i: 'relationsSourcesText',
            x: 0,
            y: 2,
            w: 3,
            h: 1,
            facets: 'QES_Relation_Source_Text.verbatim',
            aggregates: ['agg_QES_Relation_Source_Text.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.top_50_left_part'),
          },
          {
            i: 'relationsDestinationText',
            x: 3,
            y: 2,
            w: 3,
            h: 1,
            facets: 'QES_Relation_Destination_Text.verbatim',
            aggregates: ['agg_QES_Relation_Destination_Text.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.top_50_right_part'),
          },
          {
            i: 'text_datatable',
            x: 0,
            y: 3,
            w: 3,
            h: 1,
            facets: 'QES_Relation_Text.verbatim',
            aggregates: ['agg_QES_Relation_Text.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.top_50_text_part'),
          },
          {
            i: 'sentence_datatable',
            x: 3,
            y: 3,
            w: 3,
            h: 1,
            facets: 'QES_Relation_Sentence.verbatim',
            aggregates: ['agg_QES_Relation_Sentence.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.top_50_sentence_part'),
          },
          {
            i: 'relations',
            x: 0,
            y: 4,
            w: 6,
            h: 2,
            facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
            facetmax: 200,
            facetmax2: 5,
            mindoccount: 1,
            withExploration: true,
            type: 'networkgraph',
            title: i18next.t('dashboard.widget.relations_graph'),
          },
        ],
        RELATIONS_NODES_FIELDS: [
          { value: 'Organonoff', name: 'Organonoff', active: true },
          { value: 'Organization', name: 'Organization', active: true },
          { value: 'Product', name: 'Product', active: true },
          { value: 'Person', name: 'Person', active: true },
          { value: 'Concept', name: 'Concept', active: true },
          { value: 'Coldconcept', name: 'Coldconcept', active: true },
          { value: 'Location', name: 'Location', active: true },
          { value: 'Company', name: 'Company', active: true },
          { value: 'Event', name: 'Event', active: true },
          { value: 'Uppers', name: 'Uppers', active: true },
          { value: 'Media', name: 'Media', active: true },
          { value: 'JobTitle', name: 'JobTitle', active: true },
          { value: 'Demonym', name: 'Demonym', active: true },
        ],

        RELATIONS_LINKS_FIELDS: [
          { name: 'Demeurer', value: 'Demeurer', active: true },
          { name: 'Demonym', value: 'Demonym', active: true },
          { name: 'JobTitle', value: 'JobTitle', active: true },
          { name: 'Localiser', value: 'Localiser', active: true },
          { name: 'Résidence', value: 'Résidence', active: true },
          { name: 'Procéder', value: 'Procéder', active: true },
          { name: 'Espionner', value: 'Espionner', active: true },
          { name: 'Impliquer', value: 'Impliquer', active: true },
          { name: 'Confirmer', value: 'Confirmer', active: true },
        ],
      },
    },
  },
  DYNAMIC_DASHBOARD: false,
};

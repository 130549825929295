import PropTypes from 'prop-types';

const stringOrArrayOfStrings = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);

const qesdocumentPropType = PropTypes.shape({
  title: PropTypes.string,
  BASE_ID: PropTypes.string,
  Date_creation: stringOrArrayOfStrings,
  Fichier_joint: stringOrArrayOfStrings,
  QES_ArticleLoi: stringOrArrayOfStrings,
  QES_IdNatinf: stringOrArrayOfStrings,
  QES_NatureInfraction: stringOrArrayOfStrings,
  QES_ProcesVerbal: stringOrArrayOfStrings,
  QES_PV_Type: stringOrArrayOfStrings,
  QES_QualificationInfraction: stringOrArrayOfStrings,
  Phrase: stringOrArrayOfStrings,
  Titre: stringOrArrayOfStrings,
  Texte: stringOrArrayOfStrings,
});

const documentPropType = PropTypes.shape({
  qesdocument: qesdocumentPropType,
});

export {
  documentPropType,
  qesdocumentPropType,
};

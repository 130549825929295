import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { overrideRessource } from 'generic/utils/utils';
import { useTranslation } from 'react-i18next';
import { createHtmlAnnotationTag } from 'generic/utils/qesUtils';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const DocumentRelation = ({
  document,
  handleGetDocumentComplete,
}) => {
  const {
    title, pdf, linkout, attachment,
  } = useDocumentFields(document);
  const { t } = useTranslation();
  const { qesdocument } = document;

  let relation = '';
  if (qesdocument.QES_Relation_Sentence && qesdocument.QES_Relation_Keyword) {
    relation = qesdocument.QES_Relation_Sentence[0].replace(
      qesdocument.QES_Relation_Keyword[0],
      (match) => `<b>${match}</b>`,
    );
  }
  relation = relation.replace(
    new RegExp(qesdocument.QES_Relation_Source_Text_Original[0].replaceAll('\\', '&#92;'), 'ig'),
    (match) => createHtmlAnnotationTag(qesdocument.QES_Relation_Source_Type, match, true),
  );
  relation = relation.replace(
    new RegExp(qesdocument.QES_Relation_Destination_Text_Original[0].replaceAll('\\', '&#92;'), 'ig'),
    (match) => createHtmlAnnotationTag(qesdocument.QES_Relation_Destination_Type, match, true),
  );

  return (
    <Box display="block" width="100%" sx={{ wordBreak: 'break-word' }} mb={2}>
      <Box display="flex" mb="6px">
        <Box p={1} borderRight="3px solid #d3d3d3" width="100px" display="flex" alignItems="center" flexShrink="0">
          {t('results.relation')}
        </Box>
        <Box p={2} py={0.5}>
          <Typography
            variant="h6"
            component="span"
            fontSize="1.2rem"
            margin="6px 0"
            display="block"
          >
            <Box
              display="flex"
              flexWrap="wrap"
              sx={{ gap: '12px' }}
            >
              <div>{qesdocument.QES_Relation_Source_Text}</div>
              <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
              <div>{qesdocument.QES_Relation_Category[0].toUpperCase()}</div>
              <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
              <div>{qesdocument.QES_Relation_Destination_Text}</div>
            </Box>
          </Typography>
          <Box
            whiteSpace="pre-wrap"
            lineHeight="22px"
            sx={{ clear: 'both' }}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <div className="html-with-tags" dangerouslySetInnerHTML={{ __html: relation }} />
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
      >
        <Box
          py={0.5}
          px={1}
          borderRight="3px solid #d3d3d3"
          width="100px"
          flexShrink="0"
        >
          {t('results.document_one')}
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          py={0.5}
          px={2}
        >
          <Box
            component="span"
            onClick={handleGetDocumentComplete}
            sx={{ cursor: 'pointer', color: '#0782C1' }}
          >
            {qesdocument.Titre[0]}
          </Box>
          {attachment && (
            <Fragment>
              <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
              <Link
                href={attachment}
                rel="noopener"
                target="_blank"
                underline="none"
              >
                <Typography
                  component="span"
                  variant="body2"
                >
                  PV
                </Typography>
              </Link>
            </Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};

DocumentRelation.propTypes = {
  // description du document QES attendu pour ce client
  document: PropTypes.shape({
    etat: PropTypes.number,
    qesdocument: PropTypes.shape({
      QES_Relation_Source_Text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      QES_Relation_Source_Text_Original: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      QES_Relation_Source_Type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      QES_Relation_Category: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      QES_Relation_Destination_Text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      QES_Relation_Destination_Text_Original: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      QES_Relation_Destination_Type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      QES_Relation_Sentence: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      QES_Relation_Keyword: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
    files_pdf: PropTypes.string,
    files_text: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  handleGetDocumentComplete: PropTypes.func.isRequired,
};

export default DocumentRelation;

const en = {
  en: {
    translation: {
      dashboard: {
        widget: {
          level1: 'Type of procedures I',
          level2: 'Type of procedures II',
          level2_by_level1: 'Type of procedures II by type of procedures I',
          email: 'Emails',
          phone: 'Phones',
          address: 'Addresses',
          countries_mentioned: 'Map',
          location: 'Localizations',
          country: 'Country',
          city: 'Cities',
          region: 'Regions',
          object: 'Objects',
          proces_verbal: 'Proces Verbal',
          law_article: 'Law Article',
          natinf: 'Code Natinf',
          pv_type: 'Type of PV',
          nature_infraction: 'Nature of the offense',
          qualification_infraction: 'Qualification of the offense',
          lancelot_faits: 'Ontology Facts',
          lancelot_procedures: 'Ontology Procedures',
          lancelot_objets: 'Ontology Objects',
          lancelot_personnes: 'Ontology Persons',
          lancelot_arme: 'Ontology Weapons',
          lancelot_curatelle: 'Ontology Curatorships',
          lancelot_langues: 'Ontology Languages',
          lancelot_scelles: 'Ontology Sealed',
          lancelot_stupefiants: 'Ontology Narcotics',
          lancelot_lieux: 'Ontology Location',
          sealNumber_sealObject: 'Seal Number, Seal Object',
          persons_objects_concepts: 'Persons, Objects, ConceptRisk',
          person_victim: 'Victims names',
          person_mec: 'MEC names',
          persons_and_procedures: 'Persons and Procedures',
          top_50_sentence_part: 'Most frequent sentence relations',
          top_50_text_part: 'Most frequent relations',
        },
      },
      document: {
        number_PV: 'N° PV : ',
        number_natinf: 'N° Natinf : ',
        number_article_law: 'N° Law Article : ',
        nature_infraction: 'Nature of the offense : ',
        qualification_infraction: 'Qualification of the offense : ',
      },
    },
  },
};

export default en;
